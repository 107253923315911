import { FloorSchema } from './floor'
import { z } from 'zod'

export const BuildingSchema = z.object({
  id: z.number().optional(),
  address: z.string(),
  capacity: z.number(),
  city: z.string(),
  country: z.string(),
  created_at: z.string(),
  current_visitors_qrcode: z.string(),
  desk_instructions: z.string(),
  desks_spreadsheet_url: z.string(),
  enforce_security: z.boolean(),
  flex_office: z.boolean(),
  floors: z.array(FloorSchema).nullable(),
  kiosk_profile_id: z.number(),
  kiosk_theme_id: z.number(),
  logo: z.string(),
  logo_url: z.string().nullable(),
  mapwize_alias: z.string().nullable(),
  name: z.string(),
  provider_id: z.string(),
  space_id: z.number(),
  state_region: z.string(),
  threshold: z.number(),
  type: z.string(),
  updated_at: z.string(),
  url: z.string(),
  uses_guests_qr: z.boolean(),
  uses_mapwize: z.boolean(),
  zipcode: z.string(),
})
export type Building = z.infer<typeof BuildingSchema>

export type BuildingLightData = {
  id: Number
  name: string
}
