import { z } from 'zod'

export const AreaSchema = z.object({
  capacity: z.number(),
  created_at: z.string(),
  flex_office: z.boolean(),
  floor_id: z.number(),
  height: z.number(),
  id: z.number(),
  name: z.string(),
  threshold: z.number(),
  updated_at: z.string(),
  url: z.string(),
})
export type Area = z.infer<typeof AreaSchema>

export type AreaLightData = {
  id: Number
  name: string
}