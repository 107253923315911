import { AreaSchema } from './area'
import { z } from 'zod'

export const FloorSchema = z.object({
  areas: z.array(AreaSchema).nullable(),
  building_id: z.number(),
  capacity: z.number(),
  created_at: z.string(),
  desk_instructions: z.string().nullable(),
  flex_office: z.boolean().nullable(),
  floorplan_url: z.string(),
  id: z.union([z.string(), z.number()]),
  name: z.string(),
  number: z.number(),
  threshold: z.number(),
  updated_at: z.string(),
  url: z.string(),
})
export type Floor = z.infer<typeof FloorSchema>

export type FloorLightData = {
  id: Number
  name: string
}
